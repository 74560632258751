import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SessionService } from '../store/session.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private session: SessionService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let dataReq: HttpRequest<any> = req;

    if(this.session.get()){
      dataReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.session.getToken}`,
          user: `${this.session.getUserID}`,
          "Content-Security-Policy": "default-src 'self'",
          "X-Content-Type-Options":"nosniff",
          "X-Frame-Options":"DENY"
        }
      });
    }

    return next.handle(dataReq).pipe(
      map(response => {
        return this.modifyResponse(response);
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        if(error.status) {
          if (error.status === 0) {
            // client-side error
            errorMessage = `${error.message}`;
            console.error(`Error: ${errorMessage}`);
          } else {
            // server-side error
            errorMessage = `${error.error.description || error.error.message}`;
            console.error(`Error Status: ${error.status} \nMessage: ${errorMessage}`);
          }
        } else {
          errorMessage = 'Error al conectar con el servidor';
          console.error(`Error: ${errorMessage}`);
        }

        return throwError(errorMessage);
      })
   );
  }

  private modifyResponse(event: HttpEvent<any>) {
    let resp: HttpEvent<any>;
    if (event instanceof HttpResponse) {
      resp = event.clone({
        body: this.detectBodyStruct(event.body)
      });
      return resp;

    } else {
      return event;
    }
  }

  private detectBodyStruct(body: any){
    if(body.data) {
      if(body.hasOwnProperty('error')) return body.data;
      else return body;
    } else {
      return body;
    }
  }
}
