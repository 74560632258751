<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"
      ><img src="/assets/favicon.png" class="logohead"
    /></a>
    <a> CERTA </a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <!-- [picture]="user?.picture" -->
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.nombre_usuario"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
