import { NbLayoutModule, NbAccordionModule, NB_TIME_PICKER_CONFIG} from '@nebular/theme';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {MatInputModule} from '@angular/material/input';

declare global{
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     }
  }

import {
  NbCardModule,
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule,
  NbWindowModule,
  NbTimepickerModule
} from '@nebular/theme';

import { ComponentsModule } from './components/components.module';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { LocalStorageService } from './components/services/localstorage/localstorage.component';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './services/auth-guard.service';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbDateFnsDateModule } from '@nebular/date-fns';
// import { es } from 'date-fns/locale';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es-MX';
import { RequestInterceptor } from './factory/request-interceptor';
registerLocaleData(es);

@NgModule({
  declarations: [AppComponent],
  imports: [
    NbLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbCardModule,
    NbAccordionModule,
    NbDialogModule.forChild(),
    NbTimepickerModule.forRoot({
      format: 'HH:mm'
    }),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    NbDateFnsDateModule.forRoot({
      parseOptions: { locale: es },
      formatOptions: { locale: es },
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    ComponentsModule,
    StorageServiceModule,
    NbLayoutModule,
    NbEvaIconsModule,
    MatInputModule,
  ],
  providers: [
    CookieService,
    AuthGuard,
    LocalStorageService,
    {
      provide: LOCALE_ID,
      useValue: 'es-MX'
    },
    {
    provide:NB_TIME_PICKER_CONFIG,
    useValue:{}
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
