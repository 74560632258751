import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IStore, StoreInitState } from './IStore';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private storage = localStorage;
  store$: BehaviorSubject<any> = new BehaviorSubject<IStore>(this.IsJsonString(this.storage.getItem('store')) || StoreInitState);

  constructor() {
    this.store$.subscribe({
      next: (store) => {
        if(!environment.production) console.log(`store changed`, store);
      }
    });
  }

  setData(name: string, data: any) {
    const store = this.store$.getValue();
    store[name] = data;
    this.storage.setItem('store', this.IsJsonObject(store));
    this.store$.next(store);
  }

  getData(name: string) {
    const store = this.store$.getValue();
    return store[name];
  }

  get getStore(): IStore {
    return {...this.store$.getValue() };
  }

  deleteData(property: string) {
    const store = this.store$.getValue();
    delete store[property];
    this.storage.setItem('store', this.IsJsonObject(store));
    this.store$.next(store);
  }

  clear() {
    this.storage.removeItem('store');
    this.store$.next({} as IStore);
  }

  private IsJsonObject(dataValue: any) {
      "use strict";
      try {
          return JSON.stringify(dataValue);
      } catch (e) {
          return dataValue;
      }
  }

  private IsJsonString(dataValue: any){
    "use strict";
    try {
        return JSON.parse(dataValue);
    } catch (e) {
        return dataValue;
    }
  }

}
