import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-testing-components',
  templateUrl: './testing-components.component.html',
  styleUrls: ['./testing-components.component.scss']
})
export class TestingComponentsComponent {

  formData: FormGroup = new FormGroup({
    testing: new FormControl('')
  })
  testingState: any = {};
  rules: any = {
    "module": "validacion",
    "section": "pago_siniestro",
    "product": "desgravamen",
    "channel": "cc",
    "cover": "fallecimiento",
    "state": "PAG"
  }
  seguimiento_siniestro_datos_beneficiarios = {
    profile: 'Emision'
  }

  ngOnInit(){}

  send() {
    console.log(this.testingState);
    console.log(this.formData.valid);
  }

  toggleEdit() {
    this.testingState = {...this.testingState, editabled: !this.testingState.editabled}
    console.log(this.testingState);
  }

  toggleShow() {
    this.testingState = {...this.testingState, showed: !this.testingState.showed}
    console.log(this.testingState);
  }

  toggleRequired() {
    this.testingState = {...this.testingState, required: !this.testingState.required}
    console.log(this.testingState);
  }

}
