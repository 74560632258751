import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { DinamicformComponent } from './dinamicform/dinamicform.component';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbLayoutModule,
  NbSelectModule,
  NbSidebarModule,
  NbRadioModule,
  NbPopoverModule,
  NbCheckboxModule
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from '../@theme/theme.module';
import { DinamicgridComponent } from './dinamicgrid/dinamicgrid.component';
import { DinamicareaComponent } from './dinamicarea/dinamicarea.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgbdSortableHeader } from './dinamicgrid/sort.directive';
import { UpfileComponent } from './upfile/upfile.component';
import { MessageComponent } from './message/message.component';
import { DinamicdetailComponent } from './dinamicdetail/dinamicdetail.component';
import { GetelementPipe } from './dinamicgrid/getelemet.pipe';
import { ReadcsvComponent } from './readcsv/readcsv.component';
import { ViewerpdfComponent } from './viewerpdf/viewerpdf.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { RouterModule } from '@angular/router';
import { SendEmailComponent } from './send-email/send-email.component';
import { Pag404Component } from './pag404/pag404.component';
import { DocumentoCoberturaComponent } from './documento-cobertura/documento-cobertura.component';
import { ReadCsvFileComponent } from './read-csv-file/read-csv-file.component';
import { ReadEmisionComponent } from './read-emision/read-emision.component';
import { ReadSCComponent } from './read-sc/read-sc.component';
import { DetalleBloqueoComponent } from './detalle-bloqueo/detalle-bloqueo.component';
import { ModalConfirmacionComponent } from './modal-confirmacion/modal-confirmacion.component';
import {TwoDigitDecimaNumberDirective} from "./appTwoDigitDecimaNumber/app-two-digit-decima-number.directive";
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';

import {MatIconModule} from '@angular/material/icon';
import { VersionComponent } from './version/version.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ModalFindSelectorComponent } from './modal-find-selector/modal-find-selector.component';


@NgModule({
  declarations: [DinamicformComponent,
    DinamicgridComponent,
    NgbdSortableHeader,
    UpfileComponent,
    MessageComponent,
    DinamicdetailComponent,
    GetelementPipe,
    ReadcsvComponent,
    ViewerpdfComponent,
    BreadcrumbComponent,
    UploadFileComponent,
    SendEmailComponent,
    Pag404Component,
    DinamicareaComponent,
    DocumentoCoberturaComponent,
    ReadCsvFileComponent,
    ReadEmisionComponent,
    ReadSCComponent,
    DetalleBloqueoComponent,
    ModalConfirmacionComponent,
    TwoDigitDecimaNumberDirective,
    SpinnerOverlayComponent,
    VersionComponent,
    PaginatorComponent,
    ModalFindSelectorComponent
  ],

  imports: [
    CommonModule,
    ThemeModule,
    FormsModule,
    NbCardModule,
    NbButtonModule,
    NbInputModule,
    NbDialogModule.forChild(),
    NbLayoutModule,
    ReactiveFormsModule,
    NgbModule,
    NbEvaIconsModule,
    NbIconModule,
    NbCheckboxModule,
    NbSidebarModule,
    NbSelectModule,
    NbListModule,
    NbRadioModule,
    NbPopoverModule,
    PdfViewerModule,
    RouterModule,
    MatIconModule
  ],
  providers: [DecimalPipe],
  exports: [DinamicformComponent,
    DinamicareaComponent,
    DinamicgridComponent,
    UpfileComponent,
    MessageComponent,
    DinamicdetailComponent,
    ReadcsvComponent,
    ViewerpdfComponent,
    BreadcrumbComponent,
    SpinnerOverlayComponent,
    VersionComponent,
    PaginatorComponent,
    ModalFindSelectorComponent
  ],
})
export class ComponentsModule { }
