import { NbThemeService } from '@nebular/theme';
import { Component, OnInit } from "@angular/core";
import { AnalyticsService } from "./@core/utils/analytics.service";
import { LocalStorageService } from "./components/services/localstorage/localstorage.component";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "ngx-app",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {
  tema: string;
  user;
  constructor(
    private analytics: AnalyticsService,
    private localstg: LocalStorageService,
    private cookieService: CookieService,
    private themeService: NbThemeService
  ) { }

  ngOnInit(): void {


    this.analytics.trackPageViews();
    this.themeService.changeTheme('compartamos');
    const cuser = {
      perms: {}
    };

    this.localstg.storeUserOnLocalStorage(cuser)
  }

}
