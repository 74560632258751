import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthSimpleToken, NbTokenService, decodeJwtPayload, NbAuthService} from '@nebular/auth';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISession } from './ISession';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private readonly SESSION_STORE_NAME:string =  'session';
  readonly state$: BehaviorSubject<ISession | null> = new BehaviorSubject<ISession | null>(this.store.getStore.session || null);
  private refreshTokenProcess: any = null;
  private startTimeToken = null;
  private timeToRefresh = null;

  constructor(
    private store: StoreService,
    private nbTokenService: NbTokenService,
    private http: HttpClient,
    private router: Router
  ) {
    if(this.getToken){
      this.initRefreshProcess();
    }
   }

  async set(value: ISession) {

      this.setSessionToken(value);

      if(!await this.getProfileData(value.token)) throw new Error("Error al cargar el perfil");
      // redirect to dashboard
      // this.router.navigate(['main']);
  }

  delete() {
    this.store.deleteData(this.SESSION_STORE_NAME);

    localStorage.removeItem("auth_app_token");
    localStorage.removeItem("user_token");
    this.nbTokenService.clear();
    this.store.clear();

    this.state$.next(null);

    this.stopRefreshProcess();


    console.log("Close session!!!");
  }

  get() {
    return this.state$.getValue();
  }

  get getToken() {
    return this.state$.getValue()?.token;
  }

  get getDecodeToken() {
    return this.decode(this.getToken);
  }

  get getUserID() {
    return this.getDecodeToken.user?.replace('CERT-','');
  }

  getTimeLeftToRefreshToken() {
    const elapsedTime = new Date().getTime() - this.startTimeToken;
    const time = this.timeToRefresh - elapsedTime;
    if(!environment.production) console.log(`Time to refresh: ${time} miliseconds`);
    return time;
  }


  private setSessionToken(token){
      const session = {...this.get(), ...token};
      this.store.setData(this.SESSION_STORE_NAME, session);
      this.state$.next(session);
      this.initRefreshProcess();

      console.log("Set session token success");
  }

  private decode(token: string) {
    return decodeJwtPayload(token);
  }

  private initRefreshProcess(force: boolean = false) {
    const minutes_before_expirate = 3;
    const expiration = (this.getDecodeToken.exp * 1000);
    const timeToExpirate = (expiration - Date.now());

    if(timeToExpirate > 0) {
      this.timeToRefresh = (force && timeToExpirate > 0) ? this.secondsToRetry : (timeToExpirate - (minutes_before_expirate * 60 * 1000));

      this.startTimeToken = new Date().getTime();
      this.refreshTokenProcess = setTimeout(() => this.refreshTokenRequest(), this.timeToRefresh);
      this.getTimeLeftToRefreshToken();
    } else {
      this.router.navigate(['auth/logout']);
    }
  }

  private get secondsToRetry(): number {
    return (20 * 1000);
  }

  private stopRefreshProcess() {
    clearTimeout(this.refreshTokenProcess);
  }

  private refreshTokenRequest() {
    this.http.post(`${environment.aternaServiceSiniestros}${environment.pathService.refresh_token}/${this.getToken}`,{})
    .subscribe({
      next: (resp: any) => {
        this.setSessionToken({token:resp.refresh_token});
        console.log("Refresh token success");
      },
      error: (err: any) => {
          console.error(err);
          this.initRefreshProcess(true);
      }
    })
  }

  private getProfileData(token: string): Promise<any> {
    //#region old session
    return new Promise<any>((resolve, reject) => {
      let old_struct_token:any = this.decode(token);
      old_struct_token['id_usuario'] = old_struct_token.user?.replace('CERT-','') || '';

      this.http.get(`${environment.aternaServiceSiniestros}profile/${old_struct_token?.public_id}`)
      //.pipe(catchError(() => of({nombre_usuario: 'Ricardo', correo:'ricardo.zapata@matersys.com', usuario:'erzapata'})))
      .subscribe({
        next:(resp: any) => {
          const profile: any = resp[0] || resp;

          old_struct_token = { ...old_struct_token, ...profile };

          //save session
          this.nbTokenService.set(new NbAuthSimpleToken(old_struct_token,'email'));
          localStorage.setItem("user_token", JSON.stringify(old_struct_token));
          //localStorage.setItem("auth_app_token", JSON.stringify(old_struct_token));

          resolve(true);
        },
        error:(err) => {
          this.delete();
          resolve(false);
        }
      });
    });

    //#endregion
  }

}

