import { SessionService } from 'src/app/store/session.service';
import { HttpClient } from '@angular/common/http';
import { ContentChildren, Directive, ElementRef, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { FormControlDirective, FormControlName, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

interface IRulesComponent {
  showed?: IValidatorsRulesComponent | string,
  editable?: IValidatorsRulesComponent | string,
  required?: IValidatorsRulesComponent | string
}
interface IValidatorsComponent{
  id: string,
  module?: string,
  section?: string,
  product?: string,
  channel?: string,
  cover?: string,
  state?: string,
  profile?: string,
}

interface IValidatorsRulesComponent{
  module?: string | string[],
  section?: string | string[],
  product?: string | string[],
  channel?: string | string[],
  cover?: string | string[],
  siniestro_state?: string | string[],
  profile?: string | string[],
}

interface IState{
  editabled?: number | boolean;
  showed?: number | boolean;
  required?: number | boolean;
}

interface IStateComponent extends IState{
  id?: string;
  editabled?: number | boolean;
  showed?: number | boolean;
  required?: number | boolean;
}

@Directive({
  selector: '[validatorComponent]'
})
export class ValidatorComponentDirective {
  @Input() validatorComponent: string = '';
  @Input() validators: IValidatorsComponent | null = null;
  @Input() hiddenType: string = 'hidden-simple'; //hidden || hidden-simple
  @Input() externalState: IState | null = null;
  @Input() ignoreProfile: boolean = false;
  @Input() ignore: boolean = false;
  @Input() ignoreDisableClass: boolean = false;
  // @Input() state: IState | null = null;
  state: IState | null = null;

  @Output() stateChange: EventEmitter<IStateComponent> = new EventEmitter<IStateComponent>();
  @Output() onChangeState: EventEmitter<IStateComponent> = new EventEmitter<IStateComponent>();

  @ContentChildren(FormControlDirective) formControls: QueryList<any>;
  @ContentChildren(FormControlName) formControlsName: QueryList<any>;

  private rulesComponent: IRulesComponent | null = null;

  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private el: ElementRef,
  ) {
  }

  ngOnInit() {
    if(!this.validatorComponent || this.validatorComponent == '' || this.ignore) return;
    this.el.nativeElement.classList.add('validatorComponent');
    this.getRulesForStateComponentService();
  }

  ngOnChanges(changes:any) {
    if(!this.validatorComponent || this.validatorComponent == '' || this.ignore) return;
    if(this.isValuesChanged(changes)) this.calculateState();
  }

  private calculateState() {
    this.state = {...this.validRules()};
    if(this.externalState) this.state = {...this.state, ...this.externalState};



    this.implementRulesState();

    setTimeout(() => {
      const state = {...this.state, id: this.validatorComponent} as IStateComponent;
      // if(this.validatorComponent === 'validacion_datos_solicitante_agencia') console.log(state);
      this.stateChange.emit(state);
      this.onChangeState.emit(state);
    },0);
  }

  private getRulesForStateComponentService() {
    const id_component_rule = this.validatorComponent;
    if(id_component_rule !== '' && !this.rulesComponent){
      const go_service = environment.services.catCausaSiniestro;
      this.httpClient.get(`${go_service}components_rules/${id_component_rule}`)
      .subscribe({
        next: (resp: any) => {
          if(resp.datos || resp.data){
            this.rulesComponent = (resp.datos || resp.data) as IRulesComponent;
          }
        },
        error: (err) => {
          console.error(err);
        }
      })
      .add(() => this.calculateState())
    }
  }


  private validRules() {
    const validators = this.convertToString(this.validators ? { ...this.getDefaultValidators, ...this.validators } : this.getDefaultValidators);

    let newState = {};

    if(!this.rulesComponent) return {...this.getDefaultState};

    for(let attr in this.rulesComponent){
      const ruleValue = attr.indexOf("!") === -1;
      const ruleName = attr.replace("!","");

      const rules = this.rulesComponent[attr];
      newState[ruleName] = ruleValue;

      if(rules === '*') continue;

      if(Array.isArray(rules)){

        for(let rule of rules){
          newState[ruleName] = ruleValue;

          const validatorsRule = rule as IValidatorsRulesComponent;

          for(let validatorName in validatorsRule){

            const validator = validatorsRule[validatorName];
            const valueToFind = validators[validatorName];

            if(!validator || validator === "*" || !valueToFind) continue;

            if(Array.isArray(validator)){
              if(validator.indexOf(valueToFind) === -1){
                newState[ruleName] = !ruleValue;
                break;
              }
            }
          }

          if(this.validatorComponent=='datos_beneficiario') console.log(validators, validatorsRule, newState[ruleName]);

          if(newState[ruleName] == ruleValue) break;

        }
      } else {
        const validatorsRule = rules as IValidatorsRulesComponent;

        for(let validatorName in validatorsRule){

          const validator = validatorsRule[validatorName];
          const valueToFind = validators[validatorName];


          if(!validator || validator === "*" || !valueToFind) continue;


          if(Array.isArray(validator)){
            if(validator.indexOf(valueToFind) === -1){
              newState[ruleName] = !ruleValue;
              break;
            }
          }
        }
      }
    }

    return {...this.getDefaultState, ...newState};
  }

  private implementRulesState() {
    for(let rule in this.state){
      const state = this.state[rule];
      switch(rule) {
        case 'required':
          this.validInputHTML(this.el.nativeElement);
          this.validControllers();
          break;

        case 'showed':
          !state ? this.el.nativeElement.classList.add(this.hiddenType) : this.el.nativeElement.classList.remove(this.hiddenType);
          break;

        case 'editabled':
          if(this.ignoreDisableClass) return;
          !state ? this.el.nativeElement.classList.add('disabled-force') : this.el.nativeElement.classList.remove('disabled-force');
          break;
      }
    }
  }

  private validInputHTML(element: any){

    if(element.children && element.children.length > 0){
      for(let child in element.children){
        if(element.children[child] instanceof HTMLElement) this.validInputHTML(element.children[child]);
      }
    } else {
      const tag = element.tagName;

      if(['SELECT','INPUT','TEXTAREA'].indexOf(tag) !== -1) {
        const inputElement = element as HTMLInputElement;
        inputElement.required = Boolean(this.state.required);

      }
    }
  }

  private validControllers() {
    if(this.formControlsName){
      this.formControlsName.forEach(input => {
        const control = input.control;
        this.state.required ? control.setValidators(Validators.required) : control.clearValidators();
        control.updateValueAndValidity();
      })
    }

    if(this.formControls){
      this.formControls.forEach(input => {
        const control = input.control;
        this.state.required ? control.setValidators(Validators.required) : control.clearValidators();
        control.updateValueAndValidity();
      })
    }
  }

  private get getDefaultValidators(): IValidatorsComponent {

    const defaultValidator = {
      id: this.validatorComponent,
    };

    if(!this.ignoreProfile) defaultValidator['profile'] = this.sessionService.getDecodeToken.perfil;
    if(!this.ignoreProfile) defaultValidator['channel'] = this.sessionService.getDecodeToken.empresa;

    return defaultValidator;
  }

  private get getDefaultState() {
    return {
      editabled: false,
      showed: true,
      required: false
    } as IState;
  }

  private isValuesChanged(changes: any){
    for(let property in changes) {
      if(convertToString(changes[property].currentValue) !== convertToString(changes[property].previousValue)) return true;
    }

    function convertToString(value){
      try{
        return JSON.stringify(value);
      } catch(err){
        return String(value);
      }
    }

    return false;
  }

  private convertToString(validators: any){
    for(let attr in validators) validators[attr] = String(validators[attr]);
    return validators;
  }
}
