import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { User } from '../../interfaces/user-interface';

@Injectable()
export class LocalStorageService {
  KEY_STORE = 'currentuser'
  anotherTodolist = [];
  
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  
  public storeUserOnLocalStorage(value: User): void {
    this.storage.set(this.KEY_STORE, value);
  }

  public getUser() {
    return this.storage.get(this.KEY_STORE);
  }

  public getPermUser(keymodule: string) {
    const curser = this.getUser();
    const per = curser.perms[keymodule];
    return per;
  }
  
}