<nb-layout>
  <nb-layout-column>
    <form [formGroup]="formData" (submit)="send()">
      <div [validatorComponent]="'validacion_datos_solicitante'" [externalState]="testingState" (onChangeState)="testingState=$event" [validators]="seguimiento_siniestro_datos_beneficiarios">
        <label>Testing</label>
        <input nbInput class="select" placeholder="Elije una fecha" formControlName="testing">
        <!-- <input nbInput class="select" placeholder="Elije una fecha" [formControl]="formData.get('testing')"> -->
        <!-- <input nbInput class="select" placeholder="Elije una fecha" [formControlName]="'testing'"> -->
        <!-- <input nbInput class="select" placeholder="Elije una fecha" [formControl]="formData.get('testing')" [nbDatepicker]="idW"> -->
        <!-- <nb-datepicker format="dd/MM/yyyy" #idW></nb-datepicker> -->
      </div>
      <button type="submit">Enviar</button>
    </form>
    <br/>
    <br/>
    <button (click)="toggleEdit()">{{testingState?.editabled ? 'Deshabilitar':'Editar'}}</button><br/><br/>
    <button (click)="toggleShow()">{{testingState?.showed ? 'Ocultar':'Ver'}}</button>{{testingState?.showed}}<br/><br/>
    <button (click)="toggleRequired()">{{testingState?.required ? 'No requerir':'Requerir'}}</button><br/>
  </nb-layout-column>
</nb-layout>

