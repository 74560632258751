import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router) {
  }

  canActivate() {
    const token: any = JSON.parse(localStorage.getItem('user_token'));
    if (token) {
      return true;
    }
    else{
      this.router.navigate(['/auth/login']);
      return false;
    }
  }
}
